import axios from "axios";
import services from "@/axios/dbManag";
import store from "../store/index";
const { baseUrl, baseUrlV2 } = services;
const token = store.getters.tokenAdb2c;

export const sendFiles = (bodyFormData, apiLink) => {
  const config = {
    method: "post",
    baseURL: `${baseUrl}${apiLink}`,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "bearer " + token,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  };
  return services
    .sendFile(config)
    .then((res) => res)
    .catch((e) => e.response);
  // return axios({
  //   method: "post",
  //   baseURL: `${baseUrl}${apiLink}`,
  //   data: bodyFormData,
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: "bearer " + token,
  //   },
  // })
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((e) => {
  //     return e.response;
  //   });
};

export const sendFilesV2 = (bodyFormData, apiLink) => {
  const config = {
    method: "post",
    baseURL: `${baseUrlV2}${apiLink}`,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "bearer " + token,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  };
  return services
    .sendFileV2(config)
    .then((res) => res)
    .catch((e) => e.response);
  // return axios({
  //   method: "post",
  //   baseURL: `${baseUrl}${apiLink}`,
  //   data: bodyFormData,
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: "bearer " + token,
  //   },
  // })
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((e) => {
  //     return e.response;
  //   });
};
